export default {
  Accounts_Tab_Clicked: 'Accounts Tab Clicked',
  Address_Selection_Page: 'Address Selection Page',
  Auth_Otp_Requested: 'Auth otp-requested',
  BogoOffer_AddToCart: 'BogoOffer_AddToCart',
  BogoOffer_SeeAll: 'BogoOffer_SeeAll',
  Category_Clicked: 'Category Clicked',
  Category_Filter_Changed: 'Category Filter - Changed',
  Checkout_Page_Launched: 'Checkout Page launched',
  ChangeLocation_Clicked: 'ChangeLocation_Clicked',
  Coupon_Offer_Landing_Page: 'Coupon offer landing page',
  Directions: 'Directions',
  Delivery_Type: 'Delivery Type',
  // Edit_Address: 'Edit Address',
  Home_Banner_Clicked: 'home_banner_click',
  Home_Coupon_Banner_Click: 'home_coupon_banner_click',
  Home_Screen: 'Home Screen',
  // Home_Page_Category_Clicked: 'Home_Page_Category_Clicked',
  Home_Page_Offer_Section_Clicked: 'Home Page Offer Section Clicked',
  // Home_Page_Promo_Banner_Clicked: 'Home Page - Promo Banner Clicked',
  // Home_Page_Products_Added_To_Cart: 'Home Page products added to cart',
  Location_Switch: 'Location Switch',
  Logout: 'Logout',
  Login_Successful: 'Login successful',
  Min_Order_Not_Met: 'Min order not met',
  NewlyOpenedShop_Clicked: 'NewlyOpenedShop_Clicked',
  New_Location_Add_Button: 'New location add button',
  New_Location_Added: 'New location added',
  Notification_Inbox_Clicked: 'Notification Inbox clicked',
  Offer_Copies: 'Offer copies',
  Offers_Tab_Clicked: 'Offers Tab Clicked',
  Offer_Tab_Added_to_Cart: 'Offer Tab - Added to Cart',
  Offer_Tab_Offer_Zone: 'Offer Tab - Offer Zone',
  OTP_Submission: 'OTP Submission',
  Rated_And_Reviewed: 'Rated & Reviewed',
  ReferLocalShops_Clicked: 'ReferLocalShop_Clicked',
  RepeatOrder_Add: 'RepeatOrder_Add',
  Retailer_Call: 'Retailer Call',
  Shopping_Cart_Viewed: 'Shopping Cart Viewed',
  Shopping_Cart_Buy_Now: 'Shopping Cart Buy Now',
  Search_Category_Landing_Page: 'Search - Category Landing Page',
  Searched_Global: 'Searched - Global',
  Search_Element_Click: 'Search Element Click',
  // Sign_Up_Location_Set: 'Sign up - location Set',
  Sign_up_Successful: 'Sign up successful',
  SKU_Updated_To_Cart: 'SKUs Updated to Cart',
  Store_Clicked_On_Home_Page: 'Store Clicked on home page',
  Store_Landing_Page: 'Store Landing Page',
  Store_List_Search: 'Store List - Search',
  Store_List_Page: 'See All Clicked',
  Support_Call: 'call_to_order_clicked',
  Support_Chat: 'chat_order_clicked',
  ValueOffer_SeeAll: 'ValueOffer_SeeAll',

  App_Launch_Or_Address_Changed: 'App launch Or address changed',
  Stores_Not_Found_Error: 'Stores Not found error',
  Store_Landing_Category_Clicked: 'Store Landing - Category Clicked',
  Store_Landing_Offer_Added_to_Cart: 'Store Landing - Offer Added to Cart',
  Special_Offer_Landing_Page: 'Special offer landing page',
  Popular_Products_Landing_Page: 'Popular Products landing page',
  Store_Specific_Offers_Loading_Page: 'Store Specific Offers Loading Page',
  Special_Offer_Added_To_Cart: 'Special offer added to cart',
  Store_Favorite_Added: 'Store Favorite Added',
  Store_Favorite_Removed: 'Store Favorite Removed',
  Share_Referral_Clicked: 'Share_Referral_Clicked',
  // Product_discount_offers_landing_page: 'Product_discount_offers_landing_page',

  // home page flash sale
  HomePageFlashSale_Buy: 'HomePageFlashSale_Buy',
  HomePageFlashSale_Add: 'HomePageFlashSale_Add',
  HomePageFlashSale_Remove: 'HomePageFlashSale_Remove',

  SeeAll_Category: 'See All Clicked',

  // shop card
  ShopCard_Clicked: 'ShopCard_Clicked',
  ShopCard_SeeAll: 'ShopCard_SeeAll',

  // shop category
  ShopCategory_Clicked: 'ShopCategory_Category_Clicked',

  // shop filter
  ShopFilter_Clicked: 'Shop_filter_selection_click',

  // shop filters deselection
  ShopFilter_Removed: 'Shop_filter_selection_remove_click',

  //  // shop card shops page
  ShopCardshop_Clicked: 'Shop card clicked',
  ShopCardShare_Clicked: 'Share clicked',
  // Top Picks And Best Rated
  Top_Pick_Card_Clicked: 'Top_Pick_Card_Clicked',
  Best_Rated_Card_Clicked: 'Best_Rated_Card_Clicked',

  // Distance Warning
  Distance_Warning_Popup_Go_Back: 'Distance_Warning_Popup_Go_Back',
  Distance_Warning_Popup_Continue: 'Distance_Warning_Popup_Continue',
  Distance_Warning_Popup_Viewed: 'Distance_Warning_Popup_Viewed',

  // Guest Location
  Setup_Guest_Location_Clicked: 'Setup_Guest_Location_Clicked',
  Guest_Location_Selected: 'Guest_Location_Selected',
  Default_Location_Selected: 'Default_Location_Selected',
  UserLogin_Clicked: 'UserLogin_Clicked',
  GuestUser_Created: 'GuestUser_Created',

  // Rating and Review
  UserRating_Submitted: 'UserRating_Submitted',

  // FAQ
  FAQLink_Clicked: 'FAQLink_Clicked',
  FAQ_RESPONSE_NO: 'FAQResponse_No',
  FAQ_RESPONSE_YES: 'FAQResponse_Yes',

  // About Us Page CT Events
  About_Us_Page_View: 'About_Us_Page_View',
  Our_Purpose_Apply_Button_Clicked: 'Our_Purpose_Apply_Button_Clicked',
  Press_highlights_News_Article_Clicked: 'Press_highlights_News_Article_Clicked',
  Open_Positions_View_On_LinkedIn_Clicked: 'Open_Positions_View_On_LinkedIn_Clicked',
  Open_Positions_Read_More_Clicked: 'Open_Positions_Read_More_Clicked',
  Resume_Upload_Clicked: 'Resume_Upload_Clicked',
  Resume_Uploaded_Successfully: 'Resume_Uploaded_Successfully',
  Join_Us_Form_Submit_Button_Clicked: 'Join_Us_Form_Submit_Button_Clicked',
  Select_Payment_Method: 'Select Payment Method',

  // Offer section events
  See_All_Clicked: 'See All Clicked',

  // brand banner events
  Brand_Banner_Impression: 'Banner_Impression',
  Brand_Banner_Clicked: 'LL_Banner_Clicked',

  // cart + checkout
  LL_Added_To_Cart: 'LL_Added_To_Cart',
  CombinedOffer_Added: 'CombinedOffer_Added',
  View_Bill_details: 'View_Bill_Details',
  ValueOffer_Clicked: 'ValueOffer_Clicked',
  ApplyCouponCheckout_Click: 'ApplyCouponCheckout_Click',
  BottomCartButton_Clicked: 'BottomCartButton_Clicked',

  Brand_Product_Clicked: 'BrandProduct_Clicked',

  // Recent Search Events
  LL_Search: 'LL_Search',
  LL_Brand_Banner_Clicked: 'Brand_Banner_Clicked',
  LL_Product_Viewed: 'LL_Product_Viewed',

  // shop front
  Share_clicked: 'Share clicked',
  Rating_button_tapped: 'Rating button tapped',
  SupportButton_Clicked: 'SupportButton_Clicked',
  ShopSearched_Product: 'ShopSearched_Product',
  ProductSearched: 'ProductSearched',
  Offer_Clicked: 'Offer Clicked',
  LL_Banner_Clicked: 'LL_Banner_Clicked',
  See_All_Brand_Category_Clicked: 'See All Brand Category Clicked',
  See_All_Featured_Category_Clicked: 'See All Featured Category Clicked',
  See_All_Exciting_Combo_Offer: 'See All Exciting Combo Offer',
  Sub_Category_clicked: 'Sub Category clicked',
};
